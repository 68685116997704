var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-4",attrs:{"dense":"","align":"center"}},[(_vm.userAccess.canCreate)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","small":"","to":"/main/transport/create"}},[_vm._v(" "+_vm._s(_vm.$_strings.transport.ADD_TRANSPORT)+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('MasterDataCsvUpload',{attrs:{"importTypeProp":_vm.$_strings.importType.TRANSPORT,"uploadCsvProp":_vm.uploadCsv,"downloadTemplateCsvProp":_vm.downloadTemplateCsv,"dataTypeProp":"transport"},on:{"fetchList":_vm.fetchTransport}})],1),_c('v-col',{staticClass:"ml-md-auto mr-4",attrs:{"cols":"12","sm":"8","md":"3"}},[_c('v-text-field',{ref:"search",staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":_vm.$_strings.transport.SEARCHNO},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":_vm.search}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"body elevation-1",attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeaders,"items":_vm.transportItems,"item-key":"id","server-items-length":_vm.transportListTotalEntry,"options":_vm.pagination,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.$_item_per_page,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
        var item = ref.item;
return [(item.isLoadingChangeStatus)?_c('v-progress-circular',{staticClass:"mx-auto ml-2 text-center",attrs:{"color":"primary","size":"30","indeterminate":""}}):_c('v-select',{staticClass:"mt-3 pa-0 caption",staticStyle:{"width":"8rem"},attrs:{"outlined":"","dense":"","items":_vm.statusItems,"item-value":"value","item-text":"key"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"change":function($event){return _vm.onChangeIsActive(item, $event)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.imageUrl",fn:function(ref){
        var item = ref.item;
return [(!item.imageUrl)?_c('v-icon',{staticClass:"w3",attrs:{"x-large":""}},[_vm._v(" mdi-truck-outline ")]):_c('v-img',{staticClass:"w3",attrs:{"src":item.imageUrl,"aspect-ratio":"1","contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})]}},{key:"item.tt.name",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.transportTypesName))]),_c('br'),_c('span',{staticClass:"size-12"},[_vm._v(_vm._s(item.brand))])]}},{key:"item.licensePlate",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.licensePlate))]),_c('br'),_c('span',{staticClass:"size-12"},[_vm._v("Berlaku Kir : "+_vm._s(_vm.dateFormat(item.kirExpiredDate)))])]}},{key:"item.d.name",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.driverName))]),_c('br'),_c('span',{staticClass:"size-12"},[_vm._v(_vm._s(item.driverPhoneNo))])]}},{key:"item.d.licenseNo",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.driverLicenseNo))]),_c('br'),_c('span',{staticClass:"size-12"},[_vm._v("Berlaku sd : "+_vm._s(_vm.dateFormat(item.driverLicenseExp)))])]}},{key:"item.createdBy",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdBy))]),_c('br'),_c('span',{staticClass:"size-12"},[_vm._v(_vm._s(_vm.dateFormat(item.createdAt)))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.edit(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e(),(_vm.userAccess.canDelete)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteTransport(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DELETE))])]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.transport.DB_TRANSPORT)+" "),(_vm.transportItems.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }