<template>
  <v-container fluid>
    <v-row dense class="mb-4" align="center">
      <v-col cols="auto" v-if="userAccess.canCreate">
        <v-btn
          color="primary"
          small
          to="/main/transport/create"
        >
          {{$_strings.transport.ADD_TRANSPORT}}
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <MasterDataCsvUpload
          :importTypeProp="$_strings.importType.TRANSPORT"
          :uploadCsvProp="uploadCsv"
          :downloadTemplateCsvProp="downloadTemplateCsv"
          dataTypeProp="transport"
          @fetchList="fetchTransport"
        />
      </v-col>
      <v-col cols="12" sm="8" md="3" class="ml-md-auto mr-4">
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          ref="search"
          @keyup.enter="search"
          :placeholder="$_strings.transport.SEARCHNO"
          v-model="searchText"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="search"> mdi-magnify </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          class="body elevation-1"
          :loading="isLoading"
          :headers="displayedHeaders"
          :items="transportItems"
          item-key="id"
          :server-items-length="transportListTotalEntry"
          :options.sync="pagination"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
        >
          <template v-slot:[`item.isActive`]="{item}">
            <v-progress-circular
              v-if="item.isLoadingChangeStatus"
              class="mx-auto ml-2 text-center"
              color="primary"
              size="30"
              indeterminate
            ></v-progress-circular>
            <v-select
              v-else
              class="mt-3 pa-0 caption"
              style="width: 8rem"
              outlined
              dense
              :items="statusItems"
              item-value="value"
              item-text="key"
              v-model="item.isActive"
              @click.stop.prevent
              @change="onChangeIsActive(item, $event)"
            >
            </v-select>
          </template>
          <template v-slot:[`item.imageUrl`]={item}>
            <v-icon
              v-if="!item.imageUrl"
              x-large
              class="w3"
            >
              mdi-truck-outline
            </v-icon>
            <v-img
              v-else
              class="w3"
              :src="item.imageUrl"
              aspect-ratio="1"
              contain
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>
          <template v-slot:[`item.tt.name`]={item}>
            <span>{{ item.transportTypesName }}</span>
            <br/>
            <span class="size-12">{{ item.brand }}</span>
          </template>
          <template v-slot:[`item.licensePlate`]={item}>
            <span>{{ item.licensePlate }}</span>
            <br/>
            <span class="size-12">Berlaku Kir : {{ dateFormat(item.kirExpiredDate) }}</span>
          </template>
          <template v-slot:[`item.d.name`]={item}>
            <span>{{ item.driverName }}</span>
            <br/>
            <span class="size-12">{{ item.driverPhoneNo }}</span>
          </template>
          <template v-slot:[`item.d.licenseNo`]={item}>
            <span>{{ item.driverLicenseNo }}</span>
            <br/>
            <span class="size-12">Berlaku sd : {{ dateFormat(item.driverLicenseExp) }}</span>
          </template>
          <template v-slot:[`item.createdBy`]={item}>
            <span>{{ item.createdBy }}</span>
            <br/>
            <span class="size-12">{{ dateFormat(item.createdAt) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{item}">
            <v-tooltip v-if="userAccess.canUpdate" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="primary"
                  @click.stop.prevent="edit(item.id)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.EDIT}}</span>
            </v-tooltip>
            <v-tooltip v-if="userAccess.canDelete" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="red"
                  @click.stop.prevent="deleteTransport(item.id)"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.DELETE}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.transport.DB_TRANSPORT}}
              <span v-if="transportItems.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

const MasterDataCsvUpload = () => import(/* webpackChunkName: "MasterDataCsvUpload" */ '../Common/MasterDataCsvUpload');

export default {
  name: 'transport-list',
  components: {
    MasterDataCsvUpload,
  },
  data() {
    return {
      isLoading: false,
      statusItems: [
        {
          key: 'Aktif',
          value: true,
        },
        {
          key: 'Tidak Aktif',
          value: false,
        },
      ],
      searchText: this.$route.query.search || '',
      transportItems: [],
      headers: [
        {
          text: this.$_strings.transport.FOTO,
          align: 'start',
          filterable: false,
          sortable: false,
          value: 'imageUrl',
          class: 'primary white--text',
        },
        {
          text: this.$_strings.transport.TRANSPORT_NAME,
          value: 'tt.name',
          class: 'primary white--text',
        },
        {
          text: this.$_strings.transport.TRANSPORT_NUMBER,
          value: 'licensePlate',
          class: 'primary white--text',
        },
        {
          text: this.$_strings.transport.DRIVER_DATA,
          value: 'd.name',
          class: 'primary white--text',
        },
        {
          text: this.$_strings.transport.SIM_NUMBER,
          value: 'd.licenseNo',
          class: 'primary white--text',
        },
        {
          text: this.$_strings.transport.CREATED_BY,
          value: 'createdBy',
          class: 'primary white--text',
        },
        {
          text: this.$_strings.transport.IS_ACTIVE,
          value: 'isActive',
          class: 'primary white--text',
        },
        {
          text: 'Aksi',
          value: 'actions',
          width: '120px',
          class: 'primary white--text',
          sortable: false,
        },
      ],
      pagination: defaultPagination(),
      transportListSize: 10,
      transportListTotalEntry: 0,
    };
  },
  computed: {
    displayedHeaders() {
      const { canUpdate } = this.userAccess;
      return this.headers.filter((header) => {
        if (header.value === 'actions' && !canUpdate) return;
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchTransport();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    skipEmptyStringObject,
    async edit(id) {
      try {
        this.$root.$loading.show();
        const result = await this.$_services.transport.transportDetail(id);
        this.$router.push({
          name: 'transport-edit-page',
          params: {
            transportId: id,
            data: result.data,
          },
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
    search() {
      if (this.searchText !== this.$route.query.search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.searchText,
          },
        });
      }
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchTransport(this.searchText);
    },
    fetchTransport() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        licensePlate: this.searchText,
        sort: handleSortBy({ defaultValue: 'createdAt,desc', sortBy, sortDesc }),
      };
      this.isLoading = true;
      this.$_services.transport.transportList(this.skipEmptyStringObject(filters))
        .then((res) => {
          this.transportItems = res.data.contents;
          this.transportListTotalEntry = res.data.totalData;
        }).finally(() => {
          this.isLoading = false;
        });
    },
    async onChangeIsActive(item, event) {
      const payload = { ...item };
      payload.kirExpiredDate = dayjs(payload.kirExpiredDate).format('YYYY-MM-DD');
      payload.stnkExpiredDate = dayjs(payload.stnkExpiredDate).format('YYYY-MM-DD');
      payload.isActive = event;
      try {
        this.$set(item, 'isLoadingChangeStatus', true);
        await this.$_services.transport.changeStatus(payload, item.id);
        this.$dialog.notify.success(this.$_strings.transport.EDIT_STATUS_MESSAGE_TEXT);
      } catch {
        this.$nextTick(() => {
          this.$set(item, 'isActive', !event);
        });
      } finally {
        this.$delete(item, 'isLoadingChangeStatus');
      }
    },
    deleteTransport(transportId) {
      this.$dialog.warning({
        text: this.$_strings.transport.DELETE_CONFIRM_TRANSPORT,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then((transportRes) => {
        if (transportRes) {
          this.$root.$loading.show();
          this.$_services.transport.deleteTransport(transportId)
            .then(() => {
              this.$dialog.notify.success(this.$_strings.transport.DELETE_MESSAGE_TEXT_TRANSPORT);
              this.fetchTransport();
            }).finally(() => {
              this.$root.$loading.hide();
            });
        }
      });
    },
    uploadCsv(formData) {
      return this.$_services.transport.uploadCsv(formData);
    },
    downloadTemplateCsv(type) {
      return this.$_services.transport.fetchCsvTemplateData(type);
    },
  },
};
</script>
